.sidebar-mini {
    .sidebar-profile-card {
        margin-left: 0;
        .card-body {
            padding: 0;
        }
        .sidebar-profile-detail {
            height: 0;
            width: 0;
            opacity: 0;
            margin-bottom: 0;
            transition: all 0.3s ease;
        }
        .sidebar-profile-action {
            .btn-action {
                &:not(:last-child) {
                    margin-right: 0;
                    margin-bottom: $spacer * .75;
                }
            }
        }
    }
    .sidebar-border-profile-card{
        margin-left: unset;
        padding:0.2rem;
        border-radius: 0.5rem;
        .sidebar-border-profile-body{
            .sidebar-btn{
                padding: 0.25rem;
                margin-bottom: unset;
                border-radius: 0.5rem;
            }
            .sidebar-btn-icon{
                svg {
                    width:26px;
                }
            }
            .sidebar-border-user-data{
                height: 0;
                width: 0;
                opacity: 0;
                transition: all 0.3s ease;
            }
        }
    }
}
.sidebar{
    &.sidebar-base{
        &:not(.sidebar-mini) {
            .sidebar-profile-card {
                .sidebar-profile-body {
                    padding: 0;
                    img {
                        transition: all 400ms ease;
                        transition-delay: 200ms;
                        @include avatar(70, $border-radius-sm);
                    }
                }
            }
        }
        &.sidebar-mini.sidebar-hover {
            &:hover{
                .sidebar-profile-card {
                    .sidebar-profile-body {
                        padding: 0;
                        img {
                            @include avatar(70, $border-radius-sm);
                        }
                    }
                    .sidebar-profile-detail{
                        height: unset;
                        width: unset;
                        opacity: 1;
                        margin-bottom: $spacer * 1.5;
                    }
                    .sidebar-profile-action {
                        .btn-action {
                            &:not(:last-child) {
                                margin-right: $spacer;
                            }
                            margin-bottom: unset;
                        }
                    }
                }
                .sidebar-border-profile-card{
                    padding: 1.5rem;
                    margin-bottom: 2rem;
                    border-radius: 1.5rem;
                    .sidebar-border-profile-body{
                        .sidebar-btn{
                            background: rgba($primary, .2);
                            padding: 1.25rem;
                            display: inline-flex;
                            margin-bottom: 1rem;
                            border-radius: 1rem;
                            transition: all 0.3s ease;
                            &:hover{
                                cursor: pointer;
                                background: rgba($primary, .3);
                            }
                        }
                        .sidebar-btn-icon{
                            svg {
                                width: 40px;
                            }
                        }
                        .sidebar-border-user-data{
                            height: unset;
                            width: unset;
                            opacity: 1;
                        }
                    }
                }
            }
        }
    }
}
.sidebar-profile-card {
    margin-left: $spacer * .75;
    padding-bottom: $spacer;
    .sidebar-profile-body {
        text-align: center;
        img {
            transition: all 400ms ease;
            transition-delay: 200ms;
        }
    }
    .sidebar-profile-detail{
        margin-bottom: $spacer * 1.5;
        transition: all 0.3s ease;
    }
    .sidebar-profile-action {
        .btn-action {
            &:not(:last-child) {
                margin-right: $spacer;
            }
        }
    }
}
.navs-full-width{
    &.sidebar-mini{
        .sidebar-profile-card{
            .sidebar-profile-body{
                padding: 0 1rem;
            }
        }
    }
}
.sidebar-border-profile-card{
    margin-left: $spacer * .75;
    padding: 1.5rem;
    border: 2px dashed var(--#{$variable-prefix}primary);
    border-radius: 1.5rem;
    margin-bottom: 2rem;

    .sidebar-border-profile-body{
        text-align: center;
        .sidebar-btn{
            background: var(--#{$variable-prefix}primary-tint-80);
            padding: 1.25rem;
            display: inline-flex;
            margin-bottom: 1rem;
            border-radius: 1rem;
            transition: all 0.3s ease;
            &:hover{
                cursor: pointer;
                background: var(--#{$variable-prefix}primary-tint-60);
            }
        }
        .sidebar-border-data{
            transition: all 0.3s ease;
            &.sidebar-border-title{
                color: $dark;
            }
        }
    }
}
.navs-full-width{
    .border-profile-card-header{
        padding-right: $spacer * .75;
    }
    &.sidebar-mini{
        .border-profile-card-header{
            padding-left: $spacer * .75;
        }
    }
}
.notification-alert{
    position: absolute;
    top: -8px;
    right: -7px;
    background: $secondary;
    width: 1.2rem;
    height: 1.2rem;
    border-radius: 50%;
}
// Scroll bar for header
.scroll-thin {
    &::-webkit-scrollbar-track{

        border-radius: 5px;
        background-color:transparent;
    }
    &::-webkit-scrollbar{
        width: 4px;
        background-color: transparent;
    }
    &::-webkit-scrollbar-thumb{
        border-radius: 5px;
        box-shadow: inset 0 0 6px rgba(0,0,0,.3);
        background-color: rgba(255,255,255,.4);
    }
}
